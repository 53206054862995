<template>
  <th-wrapper :title="$t('pages.settings.italian_financials.title')">
    <div class="flex items-center mb-4">
      <!-- Epson Configuration-->
      <el-switch
        v-model="epsonConfig"
        :active-text="$t('pages.settings.italian_financials.toggle')"
      />
    </div>
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'

export default {
  computed: {
    epsonConfig: {
      get() {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'interfaces.printer_apis.epson_fp_it.enabled',
          false
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'interfaces.printer_apis.epson_fp_it.enabled',
          value: newValue || false
        })
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'transactions.signing.resource_type',
          value: 'registers'
        })
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'transactions.signing.type',
          value: 'it_epson'
        })
      }
    }
  }
}
</script>
