<template>
  <section>
    <financials />
  </section>
</template>

<script>
import Financials from './components/form'

export default {
  components: {
    Financials
  }
}
</script>
